/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */
import Abstract from '@/libs/classes/abstract';
import moment from 'moment-timezone';

export default class Device extends Abstract {
  static uri = '/v2/devices';

  // Scheme
  app = null;
  type = null;
  version = null;
  serial = null;
  serialnumber = null;

  software = null;
  last_ping = null;

  network = {
    remote_ip: null,
    local_ip: null,

    wireless: null,
    ethernet: null,
  };

  access_point = {
    ssid: null,
    psk: null,
  };

  peripherals = {
    timed_shield: null,
    sound: {
      available: null,
    },
  };

  balena = {
    uuid: null,
    deviceType: null,
  };

  settings = {
    controller: {
      auto_update: false,

      lease_mode: {
        enabled: null,
        lock: null,
      },
    },
    radio: {
      player: {
        enabled: null,
        path: null,
      },
    },
  };

  // Methods
  isLegacyDevice () {
    return ['MYLUXURA_CI'].includes(this.type) === true;
  }

  getSemanticSoftwareVersion () {
    if (!this.software) {
      return null;
    }

    return this.software.includes('@') ? this.software.split('@')[1] : this.software;
  }

  // Getters & Setters
  get online () {
    return (this.last_ping ? moment().diff(this.last_ping, 'hours') < 24 : false);
  }
}
